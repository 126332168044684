<script>
import get from 'lodash/get';
import DOMPurify from 'dompurify';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';
import APITraining from '@school/services/API/Training';
import TeacherSection from '@school/components/TeacherSection.vue';
import RegisterForm from '@school/components/RegisterForm.vue';
import TrainingBuyButton from '@school/components/TrainingBuyButton.vue';

const initialTrainingSlug = get(window, 'initialState.trainingSlug', null);
let initialDataUsedOnce = false;

export default {
  name: 'Training',
  components: {
    VueSimpleEmbed,
    TeacherSection,
    RegisterForm,
    TrainingBuyButton,
  },
  head() {
    if (!this.training) {
      return {};
    }

    const SEOTitle = get(this.training, 'seo_data.title');

    return {
      title: SEOTitle || this.training.name,
      titleTemplate: SEOTitle ? '' : undefined,
    };
  },
  data: () => ({
    isFetching: false,
    training: get(window, 'initialState.training', null),
  }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    sanitizedDescription() {
      return DOMPurify.sanitize(this.training.description);
    },
    sanitizedGoals() {
      return DOMPurify.sanitize(this.training.goals);
    },
    sanitizedTarget() {
      return DOMPurify.sanitize(this.training.target);
    },
    sanitizedRequirements() {
      return DOMPurify.sanitize(this.training.requirements);
    },
    lessons() {
      return this.training.items.reduce((acc, curr) => {
        let count = 0;

        if (curr.type === 'SECTION') {
          count = curr.children ? curr.children.length : 0;
        } else {
          count++;
        }

        return acc + count;
      }, 0);
    },
  },
  created() {
    const { slug } = this.$route.params;
    const useCache = (!this.authCustomer && initialTrainingSlug === slug && !initialDataUsedOnce);

    if (useCache) {
      if (!this.training) {
        this.$store.commit('errors/setError', {
          code: 404,
          message: `
            Cette formation n'existe pas ou plus sur ${this.store.name}
          `,
        });
      }

      initialDataUsedOnce = true;
      return;
    }

    this.isFetching = true;
    APITraining.getTraining(slug)
      .then((data) => (this.training = data.data))
      .finally(() => (this.isFetching = false));
  },
};
</script>

<template>
  <div class="page">
    <RegisterForm v-if="training && $route.query.acheter" :buyable="training"
      :hasPaymentPlans="$route.query.options == '1'" @enroll="$router.replace({ name: 'dashboard' })"
      @complete="$router.replace(authCustomer ? { query: {} } : { query: { connexion: 1 } })"
      @close="$routeReferrer ? $router.go(-1) : $router.replace({ query: {} })" />

    <section class="page_banner section is-medium has-background-light is-custom">
      <div class="container">
        <b-skeleton v-if="isFetching" height="40" width="280" />
        <b-skeleton v-if="isFetching" height="40" width="160" />
        <h1 v-else class="title is-custom is-size-4-mobile">
          {{ training.name }}
        </h1>
      </div>
      <div class="page_info app-header-size-t">
        <div v-if="isFetching" class="box">
          <b-skeleton height="360" />
        </div>
        <div v-else class="box p-0 overflow-hidden">
          <figure v-if="training.picture" class="image page_img">
            <img :src="`${$env.apiResourcesURL}/${training.picture}`" :alt="training.name">
          </figure>
          <div class="p-5 has-text-centered">
            <TrainingBuyButton :customer="authCustomer" :training="training" />
          </div>
          <hr class="m-0">
          <ul class="p-5 is-size-8">
            <li>
              <b-icon icon="mobile-alt" type="is-primary is-custom" />
              Accessible sur PC/Mac &amp; mobile
            </li>
            <li>
              <b-icon icon="file" pack="far" type="is-primary is-custom" />
              {{ lessons }} leçon{{ lessons > 1 ? 's' : '' }}
            </li>
            <li v-if="!store.certificate_options.disabled">
              <b-icon icon="award" type="is-primary is-custom" />
              Certificat de réussite
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="page_content section is-medium">
      <div v-if="isFetching" class="container">
        <b-skeleton height="400" />
      </div>
      <div v-else class="container">
        <figure v-if="training.promotional_video_url" class="has-background-black mb-5">
          <VueSimpleEmbed :attrs="{
            class: 'w-full',
            width: '792',
            height: '445.50'
          }" :url="training.promotional_video_url">
            <b-notification type="is-danger" :closable="false">
              Il y a eu un problème de chargement...
            </b-notification>
          </VueSimpleEmbed>
        </figure>
        <article v-if="training.description" class="mb-10">
          <h2 class="title is-custom is-4 is-size-5-touch mb-3">
            Description
          </h2>
          <div class="content" v-html="sanitizedDescription" />
        </article>
        <article v-if="training.goals" class="mb-10">
          <h2 class="title is-custom is-4 is-size-5-touch mb-3">
            Objectifs de la formation
          </h2>
          <div class="content" v-html="sanitizedGoals" />
        </article>
        <article v-if="training.target" class="mb-10">
          <h2 class="title is-custom is-4 is-size-5-touch mb-3">
            À qui cette formation s'adresse-t-elle ?
          </h2>
          <div class="content" v-html="sanitizedTarget" />
        </article>
        <article v-if="training.requirements" class="mb-10">
          <h2 class="title is-custom is-4 is-size-5-touch mb-3">
            Prérequis
          </h2>
          <div class="content" v-html="sanitizedRequirements" />
        </article>
        <article v-if="training.program_enabled">
          <b-collapse aria-id="collapsable" class="panel is-primary mb-10"
            :class="{ 'overflow-hidden': !training.program_toggled }" animation="slide"
            v-model="training.program_toggled">
            <template #trigger>
              <h2 class="panel-heading is-button is-flex is-justify-content-space-between is-align-items-center"
                aria-controls="collapsable">
                Programme
                <b-button type="is-text" class="color-inherit"
                  :icon-left="training.program_toggled ? 'chevron-down' : 'chevron-up'" />
              </h2>
            </template>
            <div class="panel-block content">
              <ol class="my-4">
                <template v-for="item in training.items">
                  <li v-if="item.type != 'SECTION' || item.children.length" :key="item.id">
                    <template v-if="item.type != 'SECTION'">
                      {{ item.name }}
                    </template>
                    <template v-else-if="item.children">
                      {{ item.name }}
                      <ol>
                        <li v-for="child in item.children" :key="child.id">
                          {{ child.name }}
                        </li>
                      </ol>
                    </template>
                  </li>
                </template>
              </ol>
            </div>
          </b-collapse>
        </article>
      </div>
    </section>

    <TeacherSection class="page_teacher" :buyable="training" />
  </div>
</template>

<style lang="scss" scoped>
$infosize: 320px;

.page {

  &_banner .container,
  &_content .container,
  &_teacher ::v-deep .container {
    padding-right: $infosize + 40;
  }

  &_img {
    max-height: 320px;

    img {
      object-fit: cover;
      object-position: center top;
    }
  }

  &_info {
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translateX($infosize / 2);
    width: 320px;
    margin-top: 20px;

    @include widescreen {
      $container-offset: (
        2 * $gap
      );
    $container-max-width: $fullhd;
    $contaier-width: min($widescreen, $container-max-width) - $container-offset;
    transform: translateX(($contaier-width / 2) - 320);
  }
}

@include touch {

  &_banner .container,
  &_content .container,
  &_teacher ::v-deep .container {
    padding-right: 0;
  }

  &_banner {
    display: flex;
    align-items: center;
  }

  &_info {
    position: static;
    transform: none;
    margin-left: 20px;
  }
}

@include mobile {
  &_banner {
    flex-direction: column;

    .title {
      text-align: center;
    }
  }

  &_info {
    width: 100%;
    max-width: 300px;
    margin: 40px 0 0;
  }
}
}
</style>
