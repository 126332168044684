<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    customer: {
      type: Object,
      default: null,
    },
    training: {
      type: Object,
      required: true,
    },
  },
  computed: {
    price() {
      return this.training.discount_price == null
        ? this.training.price
        : this.training.discount_price;
    },
    reduction() {
      return 100 - Math.round((this.training.discount_price / this.training.price) * 100);
    },
  },
  methods: {
    addToCart(isSinglePrice = true) {
      const options = isSinglePrice ? undefined : 1;
      this.$router.push({ query: { acheter: 1, options } });

      if (!isSinglePrice) {
        return;
      }

      this.$gtag.addToCart({
        items: [{
          id: this.training.uuid,
          name: this.training.name,
          quantity: 1,
          value: this.price,
        }],
      });
      this.$fbq.addToCart({
        content_ids: [this.training.uuid],
        content_name: this.training.name,
        value: this.price,
      });
    },
  },
};
</script>

<template>
  <div class="comptbb">
    <template v-if="customer && training.customer_type == 'STUDENT'">
      <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" tag="router-link"
        :to="{ name: 'mytraining', params: { slug: training.slug } }" expanded>
        Accéder
      </b-button>
    </template>
    <template v-else>
      <template v-if="price > 0 && training.payment_schedules.length">
        <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" expanded @click="addToCart(false)">
          Payer en plusieurs fois
        </b-button>
        <p class="p-2 has-text-weight-bold">
          OU
        </p>
      </template>
      <b-button class="comptbb_cta" type="is-primary is-custom" size="is-large" expanded @click="addToCart">
        <template v-if="price > 0">
          Obtenir pour {{ price | formatPrice }}
        </template>
        <template v-else>
          Accédez gratuitement
        </template>
      </b-button>
      <template v-if="training.discount_price != null">
        <p class="mt-5 is-size-7 is-size-9-mobile">
          <span class="tdecoration-line-through">
            Au lieu de {{ training.price | formatPrice }}
          </span>
          <br>
          {{ reduction }}% de réduction
        </p>
        <p v-if="training.discount_price_reason" class="opacity-70 is-size-8">
          {{ training.discount_price_reason }}
        </p>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.comptbb {
  @include mobile {
    &_cta {
      font-size: $size-7;
    }
  }
}
</style>
